<template>
  <component :is="$route.meta.layout">
    <p>404: Not Found</p>
    <v-btn
      color="primary"
      @click="goBack"
    >
      Go back to safety!
    </v-btn>
  </component>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
